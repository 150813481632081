import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable } from 'rxjs';
import { BaselineDocumentStatus, GetBaselineDocumentDto } from '../models/baseline-documents.model';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class BaselineDocumentsApiService extends ApiService {
  getBaselineDocuments$(): Observable<GetBaselineDocumentDto[]> {
    return this.get('/baselineDocuments');
  }

  getBaselineDocumentsStatuses$(): Observable<BaselineDocumentStatus[]> {
    return this.get('/baselineDocuments/statuses');
  }

  uploadBaselineDocs$(formData: FormData): Observable<any> {
    return this.postForm('/baselineDocuments/upload', formData);
  }

  deleteBaselineDocument$(uniqueId: string): Observable<any> {
    return this.delete(`/baselineDocuments/${uniqueId}`);
  }

  batchDeleteBaselineDocument$(ids: string[]): Observable<any> {
    return this.post(`/baselineDocuments/batch-delete`, ids);
  }
}

export const apiBaselineDocumentsProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiBaselineDocuments.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiBaselineDocuments.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiBaselineDocuments.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiBaselineDocuments.scopes.write],
  },
];
